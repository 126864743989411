import { computed } from 'vue'
import type { RouteLocationNormalized, LocationAsRelativeRaw, RouteQueryAndHash } from 'vue-router'
import { createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'

export type { LocationQueryRaw } from 'vue-router'

export const defaultLocale: string = import.meta.env.VITE_DEFAULT_LOCALE ?? 'en'

function customRule(choice: number, choicesLength: number) {
   if (choice === 0) {
      return 0
   }

   const teen = choice > 10 && choice < 20
   const endsWithOne = choice % 10 === 1
   if (!teen && endsWithOne) {
      return 1
   }
   if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
      return 2
   }

   return choicesLength < 4 ? 2 : 3
}

export const i18n = createI18n({
   legacy: false,
   locale: defaultLocale,
   pluralRules: {
      ru: customRule
   },
   fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE ?? 'en',
   messages
})

export const locale = computed(() => i18n.global.locale.value)

const isLocaleSupported = (locale: string) => {
   return i18n.global.availableLocales.includes(locale)
}

export const setLanguage = (locale: string) => {
   i18n.global.locale.value = locale
   document.documentElement.setAttribute('lang', locale)

   if (window?.localStorage) {
      window.localStorage.setItem('locale', locale)
   }
}

const getUserLocale = () => {
   return window.navigator.language?.split('-')[0] ?? defaultLocale
}

const getPersistedLocale = () => {
   const persistedLocale = window?.localStorage?.getItem('locale')
   if (persistedLocale && isLocaleSupported(persistedLocale)) {
      return persistedLocale
   }
}

export const findDefaultLocale = () => {
   const persistedLocale = getPersistedLocale()
   if (persistedLocale) {
      return persistedLocale
   }

   const userLocale = getUserLocale()
   if (isLocaleSupported(userLocale)) {
      return userLocale
   }

   return defaultLocale
}

export const routeMiddleware = (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
   let newLocale: string | undefined
   let prevLocale: string | undefined

   if (from.params.locale !== undefined) {
      prevLocale = typeof from.params.locale === 'string' ? from.params.locale : from.params.locale[0]
   }

   if (to.params.locale !== undefined) {
      newLocale = typeof to.params.locale === 'string' ? to.params.locale : to.params.locale[0]
   }

   if (newLocale !== undefined && newLocale !== prevLocale) {
      const foundLocale = findDefaultLocale()

      switch (true) {
         case newLocale === '': {
            if (foundLocale !== defaultLocale) {
               return { ...to, params: { ...to.params, locale: foundLocale } }
            }
            setLanguage(foundLocale)
            break
         }

         case newLocale === defaultLocale: {
            setLanguage(newLocale)
            return { ...to, params: { ...to.params, locale: '' } }
         }

         case !isLocaleSupported(newLocale): {
            return {
               ...to,
               params: { ...to.params, locale: foundLocale === defaultLocale ? '' : foundLocale }
            }
         }

         default: {
            setLanguage(newLocale)
            break
         }
      }
   }
}

export type I18nRoute = ReturnType<typeof i18nRoute>

export const i18nRoute = (to: LocationAsRelativeRaw & RouteQueryAndHash) => {
   return {
      ...to,
      params: {
         locale: locale.value === defaultLocale ? '' : locale.value,
         ...to.params
      }
   }
}

// Узнать как устанавливать title и description для страницы в зависимости от языка
